import { AuthConfig } from '@auth0/auth0-angular'

export const Auth0Environment: AuthConfig = {
    clientId: 'gKNjXLLZZ2ehaRJlnjNdRTNHZ4AKUjs2',
    domain: 'login-qa.poweredbyliquid.com',
    redirectUri: 'https://reef-qa.poweredbyliquid.com/auth-callback',
    audience: 'https://liquid-api-qa.azurewebsites.net',
    scope: 'read:current_user',
    httpInterceptor: {
        allowedList: [
            {
                uriMatcher: (uri) => uri.indexOf('oauth/token') >= 0,
                tokenOptions: {
                    audience: 'https://login-qa.poweredbyliquid.com/api/v2/',
                    scope: 'read:current_user',
                },
            },
            {
                uriMatcher: (uri) => {
                    return uri.indexOf('oauth/token') < 0 && uri.indexOf('youUp') < 0 // don't add bearer token if youUp
                },
                tokenOptions: {
                    audience: 'https://liquid-api-qa.azurewebsites.net',
                    scope: 'read:current_user',
                },
            },
        ],
    },
}

export const environment: any = {
    production: true,
    host: 'https://reef-qa.poweredbyliquid.com',
    liquidApiSettings: {
        apiServicePrefix: 'https://liquid-api-qa.azurewebsites.net',
        orgAvatarPrefix: 'https://liquid-avatars-qa.azureedge.net',
        userAvatarPrefix: 'https://liquid-profiles-qa.azureedge.net',
    },
    liquidAppHost: 'https://web-qa.poweredbyliquid.com',
    logging: {
        apiKey: 'f19bc42dcfd0fe715f9f7af24c28acbf',
        consoleBreadcrumbsEnabled: true,
        releaseStage: 'qa',
    },
    internalEmailAdress: {
        support: 'support@poweredbyliquid.com',
    },
}
